import { extendTheme } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { colors } from './colors';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';

export const theme = extendTheme({
    breakpoints,
    colors,
    components,
    fonts,
    fontSizes
});
  