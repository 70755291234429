export const components = {
    Heading: {
      baseStyle: {
        fontFamily: `'DSG', sans-serif`,
        color: `dsg.neutral.100`,
        textTransform: `uppercase`
      },
      variants: {
        alpha: {
          fontSize: `alpha`,
          lineHeight: `1`,
          letterSpacing: `0.12rem`
        },
        beta: {
          fontSize: `beta`,
          lineHeight: `1.1`
        }
      }
    },
    Text: {
      baseStyle: {
        fontFamily: `'Roboto', sans-serif`,
        fontWeight: `500`,
        color: `dsg.neutral.200`
      }
    },
    Button: {
      baseStyle: {
        borderRadius: `0`,
        textTransform: `uppercase`,
        p: `0.75rem`,
        _focus: {
          boxShadow: `none`
        }
      },
      variants: {
        'primary': {
          background: `dsg.neutral.100`,
          color: `dsg.neutral.50`
        },
      },
      sizes: {
        base: {
          w: `100%`
        }
      }
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'dsg.brand.50'
        }
      }
    }
  };
  