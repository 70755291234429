export const colors = {
    dsg: {
        brand: {
            50: `#006554`,
            100: `#147C6B`,
            200: `#98272F`
        },
        neutral: {
            50: `#FFFFFF`,
            100: `#000000`,
            200: `#4F4F4F`,
            400: `#F9F9F9`,
            500: `#EBEBEB`,
            600: `#DBDBDB`
        }
    }
};
  